import React from 'react'
import { Router } from '@reach/router'
import RAC from '../../../templates/RAC'
import DynamicNotFoundPage from '../../dynamicNotFound'

export default () => (
  <Router>
    <RAC path="/ja/infrastructure-project-finance/*" />
    <RAC path="/zh-cn/infrastructure-project-finance/*" />
    <RAC path="/zh-tw/infrastructure-project-finance/*" />
    <RAC path="/pl/infrastructure-project-finance/*" />
    <RAC path="/ru/infrastructure-project-finance/*" />
    <RAC path="/it/infrastructure-project-finance/*" />
    <RAC path="/de/infrastructure-project-finance/*" />
    <RAC path="/es/infrastructure-project-finance/*" />
    <RAC path="/fr/infrastructure-project-finance/*" />
    <RAC path="/ar/infrastructure-project-finance/*" />
    <RAC path="/id/infrastructure-project-finance/*" />
    <RAC path="/pt/infrastructure-project-finance/*" />
    <RAC path="/ko/infrastructure-project-finance/*" />
    <DynamicNotFoundPage default />
  </Router>
)
